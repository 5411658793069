import { isPlatformBrowser } from '@angular/common';
import { storageAvailable } from '../utils/storage.util';
var BaseLocalStorage = /** @class */ (function () {
    function BaseLocalStorage(memoryStorage, 
    /* tslint:disable-next-line:ban-types */
    platformId) {
        this.memoryStorage = memoryStorage;
        this.platformId = platformId;
        if (isPlatformBrowser(this.platformId) && storageAvailable('localStorage')) {
            this.storage = window.localStorage;
        }
        else {
            this.storage = this.memoryStorage;
        }
    }
    Object.defineProperty(BaseLocalStorage.prototype, "length", {
        get: function () {
            return this.storage.length;
        },
        enumerable: true,
        configurable: true
    });
    BaseLocalStorage.prototype.clear = function () {
        this.storage.clear();
    };
    BaseLocalStorage.prototype.getItem = function (key) {
        return this.storage.getItem(key);
    };
    BaseLocalStorage.prototype.key = function (index) {
        return this.storage.key(index);
    };
    BaseLocalStorage.prototype.removeItem = function (key) {
        this.storage.removeItem(key);
    };
    BaseLocalStorage.prototype.setItem = function (key, value) {
        this.storage.setItem(key, value);
    };
    return BaseLocalStorage;
}());
export { BaseLocalStorage };

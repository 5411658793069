import { StorageToken } from '@app/core/constants';
import { BehaviorSubject } from "rxjs";
var TokenService = /** @class */ (function () {
    function TokenService(storage) {
        this.storage = storage;
        this.accessToken$ = new BehaviorSubject(null);
        this.refreshToken$ = new BehaviorSubject(null);
        this.expires$ = new BehaviorSubject(null);
        this.accessTokenStorage();
        this.refreshTokenStorage();
        this.expiresTokenStorage();
    }
    TokenService.prototype.ngOnDestroy = function () {
    };
    Object.defineProperty(TokenService.prototype, "accessToken", {
        /**
         * Getter for Access Token.
         */
        get: function () {
            return this.accessToken$.getValue();
        },
        /**
         * Setter for Access Token.
         * @param value
         */
        set: function (value) {
            var _this = this;
            this.storage.setStorage({
                key: StorageToken.accessToken,
                value: value
            }).then(function (e) { return _this.accessToken$.next(value); });
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Clear Access Token.
     */
    TokenService.prototype.accessTokenClear = function () {
        var _this = this;
        this.storage.removeStorage({
            key: StorageToken.accessToken
        }).then(function (e) { return _this.accessToken$.next(null); });
    };
    /**
     * Initial set access token from storage
     */
    TokenService.prototype.accessTokenStorage = function () {
        this.accessToken$.next(this.storage.getStorage({ key: StorageToken.accessToken }));
    };
    Object.defineProperty(TokenService.prototype, "refreshToken", {
        /**
         * Getter for Refresh Token.
         */
        get: function () {
            return this.refreshToken$.getValue();
        },
        /**
         * Setter for Refresh Token.
         * @param value
         */
        set: function (value) {
            var _this = this;
            this.storage.setStorage({
                key: StorageToken.refreshToken,
                value: value
            }).then(function (e) { return _this.refreshToken$.next(value); });
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Clear Refresh Token.
     */
    TokenService.prototype.refreshTokenClear = function () {
        var _this = this;
        this.storage.removeStorage({
            key: StorageToken.refreshToken
        }).then(function (e) { return _this.refreshToken$.next(null); });
    };
    /**
     * Initial set refresh token from storage
     */
    TokenService.prototype.refreshTokenStorage = function () {
        this.refreshToken$.next(this.storage.getStorage({ key: StorageToken.refreshToken }));
    };
    Object.defineProperty(TokenService.prototype, "expires", {
        /**
         * Getter for Expires.
         */
        get: function () {
            return this.expires$.getValue();
        },
        /**
         * Setter for Expires.
         * @param value
         */
        set: function (value) {
            var _this = this;
            this.storage.setStorage({
                key: StorageToken.expires,
                value: value
            }).then(function (e) { return _this.expires$.next(value); });
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Clear expires Token.
     */
    TokenService.prototype.expiresClear = function () {
        var _this = this;
        this.storage.removeStorage({
            key: StorageToken.expires
        }).then(function (e) { return _this.expires$.next(null); });
    };
    /**
     * Initial set expires token from storage
     */
    TokenService.prototype.expiresTokenStorage = function () {
        this.expires$.next(this.storage.getStorage({ key: StorageToken.expires }));
    };
    return TokenService;
}());
export { TokenService };

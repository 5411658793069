import * as tslib_1 from "tslib";
import { AbstractStorage } from './abstract-storage.interface';
/**
 * Local Storage
 *
 * @see https://developer.mozilla.org/ru/docs/Web/API/Window/localStorage
 */
var LocalStorage = /** @class */ (function (_super) {
    tslib_1.__extends(LocalStorage, _super);
    function LocalStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LocalStorage;
}(AbstractStorage));
export { LocalStorage };

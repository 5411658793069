import { storageAvailable } from '../utils/storage.util';
/**
 * Browser session storage
 */
var BaseSessionStorage = /** @class */ (function () {
    function BaseSessionStorage(memoryStorage, 
    /* tslint:disable-next-line:ban-types */
    platformId) {
        this.memoryStorage = memoryStorage;
        this.platformId = platformId;
        if (storageAvailable('sessionStorage')) {
            this.storage = window.sessionStorage;
        }
        else {
            this.storage = this.memoryStorage;
        }
    }
    Object.defineProperty(BaseSessionStorage.prototype, "length", {
        get: function () {
            return this.storage.length;
        },
        enumerable: true,
        configurable: true
    });
    BaseSessionStorage.prototype.clear = function () {
        this.storage.clear();
    };
    BaseSessionStorage.prototype.getItem = function (key) {
        return this.storage.getItem(key);
    };
    BaseSessionStorage.prototype.key = function (index) {
        return this.storage.key(index);
    };
    BaseSessionStorage.prototype.removeItem = function (key) {
        this.storage.removeItem(key);
    };
    BaseSessionStorage.prototype.setItem = function (key, value) {
        this.storage.setItem(key, value);
    };
    return BaseSessionStorage;
}());
export { BaseSessionStorage };

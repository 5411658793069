import * as tslib_1 from "tslib";
import { HandlerEvent, ClassLists } from '@app/core/constants';
import { NavigationEnd } from '@angular/router';
var DropdownService = /** @class */ (function () {
    function DropdownService(router) {
        this.router = router;
        /**
         * Prefix for DOM-selectors.
         * @string
         */
        this.Prefix = 'ui-dropdown';
        /**
         * Lists of DOM-selectors.
         */
        this.SelectorUI = {
            link: this.Prefix + '-link',
            container: this.Prefix + '-container',
            content: this.Prefix + '-content',
            close: this.Prefix + '-close',
        };
        /**
         * Lists of DOM-animation.
         */
        this.AnimationUI = {
            sideIn: 'ui-animate-side-in-right',
            sideOut: 'ui-animate-side-out-right',
        };
        this.Settings = {
            animation: true
        };
        /**
         * Type of click.
         */
        this.ClickEvent = HandlerEvent.desktop.click;
        this.deviceHandler();
    }
    DropdownService.prototype.build = function () {
        var _this = this;
        this.router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                _this.dropDownHideAll();
            }
        });
        this.dropDownContainer();
    };
    DropdownService.prototype.rebuild = function () {
        var _this = this;
        setTimeout(function () {
            _this.dropDownContainer();
        }, 100);
    };
    DropdownService.prototype.configure = function (settings) {
        if (settings && JSON.stringify(settings) !== '{}') {
            for (var key in settings) {
                if (this.Settings.hasOwnProperty(key)) {
                    this.Settings[key] = settings[key];
                }
            }
        }
    };
    DropdownService.prototype.dropDownContainer = function () {
        var _this = this;
        setTimeout(function () {
            var DDContainer = document.querySelectorAll('.' + _this.SelectorUI.container);
            for (var i = 0; i < DDContainer.length; i++) {
                _this.dropDownLinks(DDContainer[i]);
            }
        }, 0);
    };
    DropdownService.prototype.dropDownLinks = function (container) {
        var _this = this;
        var DDLinks = container.getElementsByClassName(this.SelectorUI.link);
        var _loop_1 = function (i) {
            DDLinks[i].addEventListener(this_1.ClickEvent, function (e) {
                e.preventDefault();
                var $element = DDLinks[i];
                if ($element instanceof Element) {
                    _this.dropDownClickHandler(container, $element);
                    _this.dropDownClickOutside();
                }
            }, true);
        };
        var this_1 = this;
        for (var i = 0; i < DDLinks.length; i++) {
            _loop_1(i);
        }
    };
    DropdownService.prototype.dropDownClickHandler = function (container, link) {
        var DDContent = container.getElementsByClassName(this.SelectorUI.content);
        if (!DDContent.length) {
            return;
        }
        this.dropDownResetActiveLink();
        this.dropDownHideContent(DDContent[0]);
        this.dropDownViewContent(DDContent[0], link);
    };
    DropdownService.prototype.dropDownClickOutside = function () {
        var _this = this;
        var removeClickListener = function () {
            document.removeEventListener(_this.ClickEvent, outsideClickListener);
        };
        var outsideClickListener = function (e) {
            if (e.target.closest("." + _this.SelectorUI.link) === null) {
                _this.dropDownResetActiveLink();
                _this.dropDownHideAll();
                /**
                 * Remove handler.
                 */
                removeClickListener();
            }
        };
        document.addEventListener(this.ClickEvent, outsideClickListener);
    };
    DropdownService.prototype.dropDownViewContent = function (content, link) {
        if (content.hasAttribute('class') && content.style !== null) {
            link.classList.add(ClassLists.active);
            content.style.display = 'block';
            if (this.Settings.animation) {
                content.classList.add(this.AnimationUI.sideIn);
            }
        }
    };
    DropdownService.prototype.dropDownHideContent = function (content) {
        var e_1, _a;
        var DDContent = document.getElementsByClassName(this.SelectorUI.content);
        try {
            for (var _b = tslib_1.__values(Object.keys(DDContent)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var k = _c.value;
                if (!content.contains(DDContent[k])) {
                    this.dropDownResetView(DDContent[k]);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DropdownService.prototype.dropDownHideAll = function () {
        var e_2, _a;
        var DDContent = document.getElementsByClassName(this.SelectorUI.content);
        try {
            for (var _b = tslib_1.__values(Object.keys(DDContent)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var k = _c.value;
                this.dropDownResetView(DDContent[k]);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.dropDownResetActiveLink();
    };
    DropdownService.prototype.dropDownResetActiveLink = function () {
        var e_3, _a;
        var DDLink = document.getElementsByClassName(this.SelectorUI.link);
        try {
            for (var _b = tslib_1.__values(Object.keys(DDLink)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var l = _c.value;
                DDLink[l].classList.remove(ClassLists.active);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    DropdownService.prototype.dropDownResetView = function (element) {
        var _this = this;
        element.classList.add(this.AnimationUI.sideOut);
        setTimeout(function () {
            _this.dropDownResetAnimation(element);
        }, 500);
    };
    DropdownService.prototype.dropDownResetAnimation = function (element) {
        var e_4, _a;
        try {
            for (var _b = tslib_1.__values(Object.keys(this.AnimationUI)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                element.style.display = '';
                element.classList.remove(this.AnimationUI[key]);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
    };
    DropdownService.prototype.deviceHandler = function () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.ClickEvent = HandlerEvent.mobile.click;
        }
    };
    return DropdownService;
}());
export { DropdownService };

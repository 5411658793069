import { OnInit } from '@angular/core';
var AppComponent = /** @class */ (function () {
    function AppComponent(platformId, seo) {
        this.platformId = platformId;
        this.seo = seo;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.seo.init();
        this.onParallaxEffect();
    };
    AppComponent.prototype.onParallaxEffect = function () {
        var parallaxIt = function (e, target, movement) {
            var $this = $('.parallax__wrapper');
            var relX = e.pageX - $this.offset().left;
            var relY = e.pageY - $this.offset().top;
            TweenMax.to(target, 1, {
                x: (relX - $this.width() / 2) / $this.width() * movement,
                y: (relY - $this.height() / 2) / $this.height() * movement
            });
        };
        $('.app__layout').on('mousemove', function (e) {
            parallaxIt(e, '.parallax__needles', -30);
            parallaxIt(e, '.parallax__logo', 30);
        });
    };
    Object.defineProperty(AppComponent.prototype, "onNeedlesRootAnimation", {
        get: function () {
            return window.location.pathname === '/';
        },
        enumerable: true,
        configurable: true
    });
    return AppComponent;
}());
export { AppComponent };

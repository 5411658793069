var BaseMemoryStorage = /** @class */ (function () {
    function BaseMemoryStorage() {
        /**
         * Storage data
         */
        this.data = {};
    }
    Object.defineProperty(BaseMemoryStorage.prototype, "length", {
        get: function () {
            return Object.keys(this.data).length;
        },
        enumerable: true,
        configurable: true
    });
    BaseMemoryStorage.prototype.clear = function () {
        this.data = {};
    };
    BaseMemoryStorage.prototype.getItem = function (key) {
        return key in this.data ? this.data[key] : null;
    };
    BaseMemoryStorage.prototype.key = function (index) {
        var keys = Object.keys(this.data);
        return index >= 0 && keys.length < index ? keys[index] : null;
    };
    BaseMemoryStorage.prototype.removeItem = function (key) {
        delete this.data[key];
    };
    BaseMemoryStorage.prototype.setItem = function (key, value) {
        this.data[key] = value;
    };
    return BaseMemoryStorage;
}());
export { BaseMemoryStorage };

import * as tslib_1 from "tslib";
import { AbstractStorage } from './abstract-storage.interface';
/**
 * Session Storage
 *
 * @see https://developer.mozilla.org/ru/docs/Web/API/Window/sessionStorage
 */
var SessionStorage = /** @class */ (function (_super) {
    tslib_1.__extends(SessionStorage, _super);
    function SessionStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SessionStorage;
}(AbstractStorage));
export { SessionStorage };

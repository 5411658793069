import * as tslib_1 from "tslib";
import { AppPrefix } from '@app/app.settings';
var StorageService = /** @class */ (function () {
    function StorageService(cookieService, local, session) {
        this.cookieService = cookieService;
        this.local = local;
        this.session = session;
        this.$window = window;
        this.$storage = 'local';
    }
    /**
     * Writes down values in Cookies.
     * @params{
     *   name: string,
     *   value: any
     *   expires: number
     *   path: string
     * }
     */
    StorageService.prototype.setCookie = function (options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options.name = this.prefixValue(options.name);
                        return [4 /*yield*/, this.cookieService.set(options.name, this.convertValue(options.value), options.expires || 30, options.path || '/')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get value from cookies by key.
     * If empty value, then will return empty string.
     * @params{
     *   key: string
     * }
     */
    StorageService.prototype.getCookie = function (key) {
        return this.cookieService.get(this.prefixValue(key)) || '';
    };
    /**
     * Checking value of cookie.
     * @params{
     *   key: string
     * }
     */
    StorageService.prototype.checkCookie = function (key) {
        return this.cookieService.check(this.prefixValue(key));
    };
    /**
     * Remove all cookies.
     * Return Promise.
     * @params{
     *   key: string
     * }
     */
    StorageService.prototype.deleteCookie = function (options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options.name = this.prefixValue(options.name);
                        return [4 /*yield*/, this.cookieService.delete(options.name, options.path || '/', options.domain || '')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Remove all cookies.
     * Return Promise.
     * @params{
     *   key: string
     * }
     */
    StorageService.prototype.deleteAllCookie = function (path) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.cookieService.deleteAll(path || '/');
                return [2 /*return*/];
            });
        });
    };
    /**
     * Writes down values in Local Storage.
     * Return Promise.
     * @params{
     *   key: string,
     *   value: any,
     *   storage: string,
     * }
     */
    StorageService.prototype.setStorage = function (options, storage) {
        if (storage === void 0) { storage = this.$storage; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (storage === 'local') {
                    this.local.setItem(this.prefixValue(options.key), this.convertValue(options.value));
                }
                if (storage === 'session') {
                    this.session.setItem(this.prefixValue(options.key), this.convertValue(options.value));
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Get value from local storage by key.
     * If empty value, then will return empty object as string
     * @params{
     *   key: string,
     *   storage: string,
     * }
     */
    StorageService.prototype.getStorage = function (options, storage) {
        if (storage === void 0) { storage = this.$storage; }
        if (storage === 'local') {
            return this.local.getItem(this.prefixValue(options.key)) || null;
        }
        if (storage === 'session') {
            return this.session.getItem(this.prefixValue(options.key)) || null;
        }
        // const StorageValue = this.$window[storage + 'Storage'].getItem(this.prefixValue(options.key)) || null;
        //
        // return StorageValue;
    };
    /**
     * Get value from local storage by key.
     * If empty value, then will return empty object as string
     * @params{
     *   key: string,
     *   storage: string,
     * }
     */
    StorageService.prototype.removeStorage = function (options, storage) {
        if (storage === void 0) { storage = this.$storage; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (storage === 'local') {
                    return [2 /*return*/, this.local.removeItem(this.prefixValue(options.key)) || {}];
                }
                if (storage === 'session') {
                    return [2 /*return*/, this.session.removeItem(this.prefixValue(options.key)) || {}];
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Clear all values from session storage.
     * @params{
     *   storage: string
     * }
     */
    StorageService.prototype.clearStorage = function (storage) {
        if (storage === void 0) { storage = this.$storage; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (storage === 'local') {
                    return [2 /*return*/, this.local.clear()];
                }
                if (storage === 'session') {
                    return [2 /*return*/, this.session.clear()];
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Converting any values in string.
     */
    StorageService.prototype.convertValue = function (value) {
        return typeof value !== 'string' ? JSON.stringify(value) : value;
    };
    /**
     * Add prefix to value.
     * @param value
     */
    StorageService.prototype.prefixValue = function (value) {
        return !!AppPrefix && typeof AppPrefix === 'string' && AppPrefix.length ? AppPrefix.toLowerCase() + '-' + value : value;
    };
    return StorageService;
}());
export { StorageService };

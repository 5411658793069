import * as tslib_1 from "tslib";
import { AbstractStorage } from './abstract-storage.interface';
/**
 * Memory Storage
 *
 * @description
 * It simple storage for emulate Web Storage API
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API
 */
var MemoryStorage = /** @class */ (function (_super) {
    tslib_1.__extends(MemoryStorage, _super);
    function MemoryStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MemoryStorage;
}(AbstractStorage));
export { MemoryStorage };

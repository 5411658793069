import { Routes } from '@angular/router';
import { CustomerRouting } from '@app/core/constants';
var ɵ0 = function () { return import("./../components/root/root.module.ngfactory").then(function (m) { return m.RootModuleNgFactory; }); }, ɵ1 = function () { return import("./../components/pages/pages.module.ngfactory").then(function (m) { return m.PagesModuleNgFactory; }); }, ɵ2 = function () { return import("./../components/errors/errors.module.ngfactory").then(function (m) { return m.ErrorsModuleNgFactory; }); };
var routes = [
    {
        path: CustomerRouting.Root,
        loadChildren: ɵ0
    },
    {
        path: CustomerRouting.PagesRoot,
        loadChildren: ɵ1
    },
    {
        path: CustomerRouting.Page404,
        loadChildren: ɵ2
    },
    {
        path: '**',
        redirectTo: CustomerRouting.Page404,
        pathMatch: 'full'
    }
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1, ɵ2 };

import * as tslib_1 from "tslib";
import { Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef } from '@angular/core';
var DomService = /** @class */ (function () {
    function DomService(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    DomService.prototype.initComponent = function (component, data) {
        var _this = this;
        return new Promise(function (resolve) {
            /**
             * Create a component reference from the component
             */
            var componentRef = _this.componentFactoryResolver.resolveComponentFactory(component).create(_this.injector);
            /**
             * Write in private variable component link
             */
            _this.componentRef = componentRef;
            /**
             * Attach component to the appRef so that it's inside the ng component tree
             */
            // TODO: Fix after Angular update.
            setTimeout(function () {
                _this.appRef.attachView(componentRef.hostView);
            }, 0);
            /**
             * Insert component entered data
             */
            componentRef.instance.EnteredData = data;
            /**
             * Get DOM element from component
             */
            resolve(componentRef.hostView.rootNodes[0]);
        });
    };
    DomService.prototype.insertComponent = function (content, element) {
        var e_1, _a;
        if (element) {
            return element.appendChild(content);
        }
        var insertLayout = document.querySelectorAll('.component-insert');
        try {
            for (var _b = tslib_1.__values(Object.keys(insertLayout)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var i = _c.value;
                insertLayout[i].appendChild(content);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DomService.prototype.destroyComponent = function () {
        var _this = this;
        setTimeout(function () {
            _this.appRef.detachView(_this.componentRef.hostView);
            _this.componentRef.destroy();
        }, 500);
    };
    return DomService;
}());
export { DomService };

import * as tslib_1 from "tslib";
import { HandlerEvent } from '@app/core/constants';
export var UITemplatePopup = function (controls) {
    return "\n    <div class=\"ui-popup-wrapper\">\n      " + (controls ? '<div class="ui-popup-close"></div>' : '') + "\n      <div class=\"ui-popup-content\">\n        <div class=\"component-insert\"></div>\n      </div>\n  </div>\n  ";
};
var PopupService = /** @class */ (function () {
    function PopupService(dom) {
        this.dom = dom;
        /**
         * Prefix for DOM-selectors.
         * @string
         */
        this.Prefix = 'ui-popup';
        /**
         * Lists of DOM-selectors.
         */
        this.SelectorUI = {
            appRoot: 'app-root',
            container: this.Prefix + '-container',
            overlay: this.Prefix + '-overlay',
            close: this.Prefix + '-close'
        };
        /**
         * Service settings.
         */
        this.Settings = {
            /**
             * Option for moving of modal box.
             * @Boolean
             */
            draggable: false,
            /**
             * Option for creating of controls buttons.
             * @Boolean
             */
            controls: false,
            /**
             * Option for opening of modal box on full screen.
             * @Boolean
             */
            fullScreen: false,
            /**
             * Option for creating of overlay wrapper.
             * @Boolean
             */
            overlay: true,
            /**
             * Option for blur of background.
             * @Boolean
             */
            blur: false,
            /**
             * Option for animation of popup.
             * @Boolean
             */
            animation: true,
            /**
             * Option for remove popup from ESC press.
             * @Boolean
             */
            escClose: true,
            /**
             * Callback on ready DOM.
             */
            onReady: null,
            /**
             * Callback on event of creation.
             */
            onCreate: null,
            /**
             * Callback on event of closing.
             */
            onClose: null,
        };
        /**
         * Lists of animation's selectors.
         */
        this.Animation = {
            viewIn: 'ui-animate-zoom-in',
            viewOut: 'ui-animate-zoom-out',
            blur: 'ui-animate-blur'
        };
        /**
         * Type of click.
         */
        this.ClickEvent = HandlerEvent.desktop.click;
        this.deviceHandler();
    }
    PopupService.prototype.configure = function (settings) {
        for (var key in settings) {
            if (this.Settings.hasOwnProperty(key)) {
                this.Settings[key] = settings[key];
            }
        }
    };
    PopupService.prototype.onBuild = function () {
        this.factoryPopupTemplate();
    };
    PopupService.prototype.factoryPopupTemplate = function () {
        var _body = document.getElementsByTagName('body')[0];
        var _template = UITemplatePopup(this.Settings.controls);
        var _existence = this.existencePopup();
        var templateDiv = document.createElement('div');
        templateDiv.className = this.SelectorUI.container + ("" + (this.Settings.fullScreen ? '-full' : ''));
        templateDiv.innerHTML = _template;
        var overlayDiv = document.createElement('div');
        overlayDiv.className = this.SelectorUI.overlay;
        if (_existence) {
            this.destroyPopupContainer();
        }
        this.insertPopupTemplate(templateDiv, overlayDiv);
        _body.classList.add('no-scroll');
    };
    PopupService.prototype.insertPopupTemplate = function (templateDiv, overlayDiv) {
        var appRoot = document.getElementsByTagName(this.SelectorUI.appRoot) || null;
        if (!appRoot && !appRoot.length) {
            return;
        }
        var $appRoot = appRoot[0] || null;
        if ($appRoot && $appRoot instanceof Element) {
            $appRoot.appendChild(templateDiv);
            this.onCreateEvent();
            if (this.Settings.animation) {
                this.onAnimationPopupView(templateDiv);
            }
            if (this.Settings.blur) {
                this.onBlurLayout();
            }
            if (this.Settings.controls) {
                this.onPopupControls(templateDiv);
            }
            if (this.Settings.escClose) {
                this.onEscHandler();
            }
            if (this.Settings.overlay) {
                $appRoot.appendChild(overlayDiv);
                this.onPopupOverlay();
            }
        }
    };
    PopupService.prototype.destroyPopupContainer = function () {
        var _this = this;
        var Container = this.getAllElementsBySelector(this.SelectorUI.container);
        var Body = document.getElementsByTagName('body')[0];
        Body.classList.remove('no-scroll');
        Container.forEach(function ($el) {
            _this.onAnimationPopupHide($el);
        });
        this.onBlurLayoutReset();
    };
    PopupService.prototype.destroyPopupOverlay = function () {
        var Overlay = this.getAllElementsBySelector(this.SelectorUI.overlay);
        Overlay.forEach(function ($el) {
            $el.remove();
        });
    };
    PopupService.prototype.onPopupControls = function (container) {
        var _this = this;
        var Close = this.getElementBySelector(this.SelectorUI.close, container);
        Close.forEach(function ($el) {
            _this.onCloseHandler($el);
        });
    };
    PopupService.prototype.onPopupOverlay = function () {
        var _this = this;
        var Overlay = this.getAllElementsBySelector(this.SelectorUI.overlay);
        Overlay.forEach(function ($el) {
            _this.onCloseHandler($el);
        });
    };
    PopupService.prototype.onCloseHandler = function (element) {
        var _this = this;
        element.addEventListener(this.ClickEvent, function (e) {
            e.preventDefault();
            _this.onCloseEvent();
        });
    };
    PopupService.prototype.onEscHandler = function () {
        var _this = this;
        var removeKeyDown = function () {
            document.removeEventListener('keydown', keyDownListener);
        };
        var keyDownListener = function (e) {
            if (!_this.existencePopup()) {
                return removeKeyDown();
            }
            if (e.keyCode === 27) {
                _this.onCloseEvent();
                removeKeyDown();
            }
        };
        document.addEventListener('keydown', keyDownListener);
    };
    PopupService.prototype.existencePopup = function () {
        var Container = this.getAllElementsBySelector(this.SelectorUI.container);
        if (Container.length !== 0) {
            return true;
        }
    };
    PopupService.prototype.onAnimationReset = function (element) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(Object.keys(this.Animation)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var i = _c.value;
                element.classList.remove(this.Animation[i]);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    PopupService.prototype.onAnimationPopupHide = function (element) {
        var Wrapper = element.children;
        if (Wrapper.length) {
            var $el = Wrapper[Wrapper.length - 1] || null;
            this.onAnimationReset($el);
            if ($el && $el instanceof Element) {
                $el.classList.add(this.Animation.viewOut);
                var timeout = parseFloat(getComputedStyle($el)['transitionDuration']) * 1000 || 500;
                setTimeout(function () {
                    element.remove();
                }, timeout);
            }
        }
    };
    PopupService.prototype.onAnimationPopupView = function (element) {
        var Wrapper = element.children;
        if (Wrapper.length) {
            var $el = Wrapper[Wrapper.length - 1] || null;
            this.onAnimationReset($el);
            if ($el && $el instanceof Element) {
                $el.classList.add(this.Animation.viewIn);
            }
        }
    };
    PopupService.prototype.onBlurLayout = function () {
        var _this = this;
        var Page = document.querySelectorAll('.pg-layout');
        Page.forEach(function ($el) {
            $el.classList.add(_this.Animation.blur);
        });
    };
    PopupService.prototype.onBlurLayoutReset = function () {
        var _this = this;
        var Page = document.querySelectorAll('.pg-layout');
        Page.forEach(function ($el) {
            $el.classList.remove(_this.Animation.blur);
        });
    };
    PopupService.prototype.onCreateEvent = function () {
        var _onCreate = this.Settings.onCreate;
        if (!_onCreate && typeof _onCreate !== 'function') {
            return;
        }
        _onCreate();
    };
    PopupService.prototype.onCloseEvent = function () {
        var _onClose = this.Settings.onClose;
        if (_onClose && typeof _onClose === 'function') {
            _onClose();
        }
        this.destroyPopupContainer();
        this.destroyPopupOverlay();
    };
    PopupService.prototype.setCreateEvent = function (call) {
        if (call.hasOwnProperty('onCreate')) {
            this.Settings.onCreate = call['onCreate'];
        }
    };
    PopupService.prototype.setCloseEvent = function (call) {
        if (call.hasOwnProperty('onClose')) {
            this.Settings.onCreate = call['onClose'];
        }
    };
    PopupService.prototype.getAllElementsBySelector = function (selector) {
        return document.querySelectorAll("." + selector);
    };
    PopupService.prototype.getElementBySelector = function (selector, parent) {
        return parent.querySelectorAll("." + selector);
    };
    PopupService.prototype.deviceHandler = function () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.ClickEvent = HandlerEvent.mobile.click;
        }
    };
    return PopupService;
}());
export { PopupService };
